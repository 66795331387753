<template>
    <div>
        <el-form :inline="true" ref="form" :model="form" class="demo-form-inline logs-form">
            <el-form-item label="类型">
                <el-select v-model="form.type" placeholder="请选择">
                    <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="操作人">
                <el-input v-model="form.name" placeholder="请输入操作人"></el-input>
            </el-form-item>
            <el-form-item label="信息">
                <el-input v-model="form.title" placeholder="请输入信息"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>

        <div class="table-box">
            <el-table
                :data="tableData"
                border
                style="width: 100%">
                <el-table-column
                type="index"
                label="序号"
                align="center"
                width="150">
                </el-table-column>
                <el-table-column
                label="时间"
                align="center"
                width="200">
                    <template slot-scope="scope">
                        {{scope.row.createTime | date}}
                    </template>
                </el-table-column>
                <el-table-column
                prop="createBy"
                label="操作人"
                align="center"
                width="200">
                </el-table-column>
                <el-table-column
                prop="type"
                label="类型"
                align="center"
                width="200">
                </el-table-column>
                <el-table-column
                prop="title"
                label="信息"
                align="center"
                width="300">
                </el-table-column>
                <el-table-column
                label="操作"
                align="center">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-pagination
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-size="form.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <readonly-page ref="readonlypage"></readonly-page>

    </div>
</template>
<script>
import ReadonlyPage from './ReadonlyPage.vue'
import { logsmanagerAPI } from 'liankong-ui-api'

  export default {
    data() {
        return {
            total: 0,
            form: {
                type: '',
                name: '',
                title: '',
                pageNum: 1,
                pageSize: 10
            },
            tableData: [],
            typeList:[{
                label: "消息日志",
                value: "liankong-service-message"
            },{
                label: "用户日志",
                value: "liankong-user-service"
            },{
                label: "文档日志",
                value: "liankong-service-document"
            },{
                label: "共享日志",
                value: "liankong-service-share"
            },{
                label: "用户登录/退出日志",
                value: "liankong-user-auth"
            }]

        }
    },
    components: {
        ReadonlyPage
    },
    created(){
        this.onLoadList();
    },
    methods: {
        onLoadList(){
            logsmanagerAPI.getLogLists(this.form).then(res=>{
                if(res.code == 0) {
                    this.total = res.data.total;
                    this.tableData = res.data.records;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        onSubmit() {
            this.onLoadList();
        },
        handleClick(row) {
            this.$refs.readonlypage.onlyFlag = true;
            this.$nextTick(()=>{
                this.$refs.readonlypage.creatLoadInit(true, row);
            })
        },
        handleCurrentChange(val){
            this.form.pageNum = val;
            this.onLoadList()
        }
    }
  }
</script>
<style lang="less" scoped>
.logs-form {
    padding: 20px;
}
.table-box {
    margin: 0 20px 0px 20px;
    padding-bottom: 20px;
}
.pagebox {
    padding: 0px 20px 20px 20px;
}
</style>